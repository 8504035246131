import { Link, LinkProps } from 'react-router';

const LinkWrapper: React.FunctionComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>> = ({
  children,
  to,
  ...rest
}) => {
  return <Link
    className='
      text-v-purple font-medium
      outline-offset-2
      focus-visible:outline-2 focus-visible:outline-blue-300
    '
    to={to}
    {...rest}
  >
    {children}
  </Link>
};

export default LinkWrapper;
