import './styles.css';

const Loading = ({ size = 'base' }: { size?: 'base' | 'large' }) => {
  const style = {
    width: size === 'base' ? '50px' : '72px',
    height: size === 'base' ? '50px' : '72px',
    '--loader-border-width': size === 'base' ? '8px' : '10px'
  };

  return <div className='loader' style={style} />;
};

export default Loading;
