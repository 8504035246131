import iconWarn from 'assets/images/icon-warn.png';

const WarnText = ({ text }: { text: string }) => {
  if (!text) {
    return <></>;
  }

  return (
    <div className='
      flex flex-row 
      gap-x-1
      text-xs text-yellow-800
    '>
      <img
        src={iconWarn}
        className='w-[14px] h-4 -mt-[1px]'
        alt='Warn icon'
      />
      <span>{text}</span>
    </div>
  );
};

export default WarnText;
