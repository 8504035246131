const Button: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    isActive?: boolean;
    title?: string;
  }
> = ({
  children,
  isActive,
  style,
  title,
  ...rest
}) => {
  return (
    <button
      type='button'
      className='
        pl-3 h-10
        grid grid-cols-[auto_1fr] gap-3 justify-items-start items-center
        rounded-[10px]
        outline-offset-2
        hover:bg-zinc-50
        active:bg-amber-100
        focus-visible:outline-2 focus-visible:outline-blue-300
        transition ease-out duration-300
      '
      style={{
        backgroundColor: isActive ? '#FFF9EB' : undefined,
        ...(style || {}),
      }}
      {...rest}
    >
      {children}
      <span className='font-medium text-sm'>{title}</span>
    </button>
  );
};

export default Button;
