import axios, { AxiosError } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { getAuth } from 'firebase/auth';

export const virtuosisInsance = axios.create({
  baseURL: 'https://virtuosis-client-prod.azure-api.net',
  timeout: 60000,
});

const retryConfig: IAxiosRetryConfig = {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error: AxiosError) => {
    const status = error?.response?.status;

    if (status === undefined) {
      return true;
    }

    const isRateLimit = status === 429;
    const isServerError = status >= 500 && status < 600;

    return isRateLimit || isServerError;
  },
};

axiosRetry(virtuosisInsance, retryConfig);

virtuosisInsance.interceptors.request.use(
  async (config) => {
    const { currentUser } = getAuth();
    const token = await currentUser?.getIdToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
);
