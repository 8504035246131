import { useEffect } from 'react';

import { useAuth } from 'context';

import { useNavigate } from 'react-router';

import Init from 'components/Init';

const EmailProtected = ({ children }: { children: JSX.Element }) => {
  const { auth } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth && !auth.emailVerified) {
      navigate('/email', { replace: true });
    }
  }, [auth, navigate]);

  return !auth ? <Init /> : children;
};

export default EmailProtected;
