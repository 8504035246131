import iconSuccess from 'assets/images/icon-success.png';

const SuccessText = ({ text }: { text: string }) => {
  if (!text) {
    return <></>;
  }

  return (
    <div className='
      flex flex-row
      gap-x-1
      text-xs text-green-800
    '>
      <img
        src={iconSuccess}
        className='w-[14px] h-4 -mt-[1px]'
        alt='Error icon'
      />
      <span>{text}</span>
    </div>
  );
};

export default SuccessText;
