import Logo from 'components/Logo';

import logoApple from 'assets/images/logo-apple.png';
import logoEpfl from 'assets/images/logo-epfl.png';
import logoInnovaud from 'assets/images/logo-innovaud.png';
import logoImd from 'assets/images/logo-imd.png';
import logoMicrosoft from 'assets/images/logo-microsoft.png';
import logoNvidia from 'assets/images/logo-nvidia.png';
import logoPwc from 'assets/images/logo-pwc.png';
import logoSwiss from 'assets/images/logo-swiss.png';
import logoSwisscom from 'assets/images/logo-swisscom.png';

const About = () => {
  return (
    <div className='
      hidden md:grid grid-rows-[auto_1fr]
      bg-angular-gradient bg-cover bg-center
      py-20 px-10 xl:px-20
    '>
      <div className='
        grid grid-rows-[auto_auto]
         gap-y-10 
      '>
        <Logo />
        <p style={{
          fontSize: '40px',
          fontWeight: '600',
          lineHeight: '52px',
          maxWidth: '580px'
        }}>
          Health, wellbeing & communication insights from
          <span className='text-v-purple'> your voice</span>
        </p>
      </div>
      <div className='content-end'>
        <div className='
          flex flex-row flex-wrap gap-x-20 gap-y-4
          justify-center items-center
        '>
          <img
            src={logoMicrosoft}
            className='min-w-[106px] max-h-[36px]'
            alt='Microsoft logo'
          />
          <img
            src={logoApple}
            className='min-w-[32px] max-h-[32px]'
            alt='Apple logo'
          />
          <img
            src={logoNvidia}
            className='min-w-[108px] max-h-[26px]'
            alt='Nvidia logo'
          />
          <img
            src={logoSwisscom}
            className='min-w-[46px] max-h-[44px]'
            alt='Swisscom logo'
          />
          <img
            src={logoPwc}
            className='min-w-[46px] max-h-[35px]'
            alt='Pwc logo'
          />
          <img
            src={logoSwiss}
            className='min-w-[128px] max-h-[34px]'
            alt='Swiss logo'
          />
          <img
            src={logoEpfl}
            className='min-w-[55px] max-h-[16px]'
            alt='EPFL logo'
          />
          <img
            src={logoInnovaud}
            className='min-w-[93px] max-h-[28px]'
            alt='Innovaud logo'
          />
          <img
            src={logoImd}
            className='min-w-[72px] max-h-[38px]'
            alt='IMD logo'
          />
        </div>
      </div>
    </div>
  );
};

export default About;
