import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

export const apiBaseUrl = 'https://virtuosis-client-prod.azure-api.net';

const bodyScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth;

export const hideBodyScrollBar = () => {
  const padding = `0 ${bodyScrollBarWidth()}px 0 0`;
  document.body.style.overflow = 'hidden';
  document.body.style.padding = padding;
};

export const showBodyScrollBar = () => {
  document.body.style.overflow = 'visible';
  document.body.style.padding = '0px';
};

export const newUTCString = (date: Date, subtractSeconds = 0) => {
  return dayjs(date)
    .utc()
    .subtract(subtractSeconds, 'second')
    .format('M/D/YYYY h:mm:ss A');
};

export const delayedCall = <T>(callback: () => Promise<T>, time: number = 2000) => {
  return new Promise<T>((resolve) => {
    setTimeout(async () => {
      resolve(await callback());
    }, time)
  });
};

export const isObject = (value: any): boolean => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};
