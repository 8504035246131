import { AuthProvider, DateProvider } from 'context';
import { initializeFirebaseApp } from 'helpers/firebase';
import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  Navigate,
  useLocation,
  matchRoutes,
  RouterProvider,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router';
import {
  AuthProtected,
  EmailProtected,
  SubscriptionProtected,
} from 'routes';
import * as Sentry from '@sentry/react';

import {
  Dashboard,
  EmailValidation,
  Login,
  PasswordReset,
  RequestTrial,
  SignUp,
} from 'components';

import 'assets/css/styles.css';

initializeFirebaseApp();

Sentry.init({
  dsn: 'https://05339ef28e95904d2d1632a2281a5017@o4506237862412288.ingest.sentry.io/4506288835133440',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV, 
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'signup',
      element: <SignUp />,
    },
    {
      path: 'password',
      element: <PasswordReset />,
    },
    {
      path: 'email',
      element: (
        <AuthProtected>
          <EmailValidation />
        </AuthProtected>
      ),
    },
    {
      path: 'trial',
      element: (
        <AuthProtected>
          <EmailProtected>
            <RequestTrial />
          </EmailProtected>
        </AuthProtected>
      ),
    },
    {
      path: 'wellbeing',
      element: (
        <AuthProtected>
          <EmailProtected>
            <SubscriptionProtected>
              <Dashboard />
            </SubscriptionProtected>
          </EmailProtected>
        </AuthProtected>
      ),
    },
    {
      path: 'commcoach',
      element: (
        <AuthProtected>
          <EmailProtected>
            <SubscriptionProtected>
              <Dashboard />
            </SubscriptionProtected>
          </EmailProtected>
        </AuthProtected>
      ),
    },
    {
      path: 'settings',
      element: (
        <AuthProtected>
          <EmailProtected>
            <SubscriptionProtected>
              <Dashboard />
            </SubscriptionProtected>
          </EmailProtected>
        </AuthProtected>
      ),
    },
    {
      path: '*',
      element: <Navigate to='/wellbeing' replace />,
    },
  ],
);

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <StrictMode>
      <AuthProvider>
        <DateProvider>
          <RouterProvider router={router} />
        </DateProvider>
      </AuthProvider>
    </StrictMode>
  );
