import { useAuth } from 'context';
import {
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  sendEmailVerification,
  signInWithPopup,
} from 'firebase/auth';
import { isObject } from 'helpers';
import {
  isAccountExistsWithDifferentCredentialError,
  isCancelledPopupRequest,
  isPopupClosedByUser,
  isTooManyRequests,
} from 'helpers/firebase';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';

import Button from 'components/Button';

import iconGoogle from 'assets/images/icon-google.png';
import iconMicrosoft from 'assets/images/icon-microsoft.png';

const OAuth = () => {
  const [googleError, setGoogleError] = useState('');
  const [microsoftError, setMicrosoftError] = useState('');

  const { pendingCredential, setPendingCredential } = useAuth();

  const navigate = useNavigate();

  const resetErrors = () => {
    setGoogleError('');
    setMicrosoftError('');
  };

  const handleAccountAlreadyExists = (err: any, provider: 'Google' | 'Microsoft'): string => {
    const baseMessage = 'Your email is already used with a different login method';

    if (!isObject(err)) {
      return baseMessage;
    }

    const { customData } = err || {};

    if (!isObject(customData)) {
      return baseMessage;
    }

    const { email } = customData || {};
    const credential = OAuthProvider.credentialFromError(err);

    if (!credential || typeof email !== 'string' || !email) {
      return baseMessage;
    }

    setPendingCredential({ credential, email });

    return `${baseMessage} To login with ${provider}, please login with a non-${provider} login method and we will link your accounts.`;
  };

  const handleGoogleLogin = async () => {
    try {
      resetErrors();

      const firebaseAuth = getAuth();
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(firebaseAuth, provider);
      const { user } = userCredential

      const { isNewUser } = getAdditionalUserInfo(userCredential) || {};

      if (!user.emailVerified && isNewUser) {
        await sendEmailVerification(user);
      }

      if (
        pendingCredential
        && pendingCredential.email === user.email
        && pendingCredential.credential.providerId === 'microsoft.com'
      ) {
        await linkWithCredential(user, pendingCredential.credential);
      }

      navigate('/');
    } catch (err) {
      if (isCancelledPopupRequest(err) || isPopupClosedByUser(err)) {
        return;
      }

      if (isAccountExistsWithDifferentCredentialError(err)) {
        setGoogleError(handleAccountAlreadyExists(err, 'Google'));
        return;
      }

      if (isTooManyRequests(err)) {
        setGoogleError('Too many requests - please try again later');
        return;
      }

      setGoogleError('An error occurred - please try again');
      Sentry.captureException(err);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      resetErrors();

      const firebaseAuth = getAuth();
      const provider = new OAuthProvider('microsoft.com');

      provider.addScope('User.Read');
      provider.addScope('api://1fe21a44-dcf3-4406-be96-681861c25183/User.Manage');

      const userCredential = await signInWithPopup(firebaseAuth, provider);
      const { user } = userCredential;

      const { isNewUser } = getAdditionalUserInfo(userCredential) || {};

      if (!user.emailVerified && isNewUser) {
        await sendEmailVerification(user);
      }

      if (
        pendingCredential
        && pendingCredential.email === user.email
        && pendingCredential.credential.providerId === 'google.com'
      ) {
        await linkWithCredential(user, pendingCredential.credential);
      }

      navigate('/');
    } catch (err) {
      if (isCancelledPopupRequest(err) || isPopupClosedByUser(err)) {
        return;
      }

      if (isAccountExistsWithDifferentCredentialError(err)) {
        setMicrosoftError(handleAccountAlreadyExists(err, 'Microsoft'));
        return;
      }

      if (isTooManyRequests(err)) {
        setMicrosoftError('Too many requests - please try again later');
        return;
      }

      setMicrosoftError('An error occurred - please try again');
      Sentry.captureException(err);
    }
  };

  return (
    <>
      <Button
        pallet='secondary'
        errorText={googleError}
        onClick={handleGoogleLogin}
      >
        <div className='flex gap-2.5 items-center'>
          <img
            src={iconGoogle}
            className='w-[20px] h-[20px]'
            alt='Google icon'
          />
          <span>
            Login with Google
          </span>
        </div>
      </Button>
      <Button
        pallet='secondary'
        errorText={microsoftError}
        onClick={handleMicrosoftLogin}
      >
        <div className='flex gap-2.5 items-center'>
          <img
            src={iconMicrosoft}
            className='w-[20px] h-[20px]'
            alt='Microsoft icon'
          />
          <span>
            Login with Microsoft
          </span>
        </div>
      </Button>
    </>
  );
};

export default OAuth;
