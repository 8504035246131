import { useNavigate } from 'react-router';

import logoNamedAi from 'assets/images/logo-named-ai.png';

const Logo: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    autoHeight?: boolean;
  }
> = ({
  autoHeight = false,
  style,
  ...rest
}) => {
  const { width } = style || {};
  
  const navigate = useNavigate();

  return (
    <button
      type='button'
      tabIndex={-1}
      onClick={() => navigate('/')}
      style={{
        width: width || 160,
        ...(style || {}),
      }}
      {...rest}
    >
      <img
        className={autoHeight ? 'h-10 md:h-12' : 'h-12'}
        src={logoNamedAi}
        alt='Virtuosis logo'
      />
    </button>
  );
};

export default Logo;
