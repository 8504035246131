import { createUserWithEmailAndPassword, getAuth, sendEmailVerification } from 'firebase/auth';
import { isEmailAlreadyInUseError, isTooManyRequests } from 'helpers/firebase';
import { useFormValidation } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';

import Button from 'components/Button';
import { EmailInput, PasswordInput } from 'components/Input';
import Link from 'components/Link';

import About from './About';
import Header from './Header';
import OAuth from './OAuth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const {
    emailError,
    passwordError,
    isEmailValid,
    isPasswordValid,
    resetFormErrors,
    setEmailError,
  } = useFormValidation();

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      setError('');
      resetFormErrors();

      const passwordTrimmed = password.trim();

      const isEmailInvalid = !isEmailValid(email);
      const isPasswordInvalid = !isPasswordValid(passwordTrimmed);

      if (isEmailInvalid || isPasswordInvalid) {
        return;
      }

      setIsLoading(true);

      const firebaseAuth = getAuth();
      const { user } = await createUserWithEmailAndPassword(firebaseAuth, email, passwordTrimmed);

      if (!user.emailVerified) {
        await sendEmailVerification(user);
      }

      setIsLoading(false);

      navigate('/');
    } catch (err) {
      if (isEmailAlreadyInUseError(err)) {
        setEmailError('Email address is already in use by another account or OAuth provider');
      } else if (isTooManyRequests(err)) {
        setError('Too many requests - please try again later');
      } else {
        setError('An error occurred - please try again');
        Sentry.captureException(err);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'Virtuosis - Create account';
  }, []);
  
  return (
    <div className='min-h-screen grid md:grid-cols-2'>
      <div className='absolute w-full block md:hidden'>
        <Header />
      </div>
      <div className='
        flex justify-center items-center
        px-5 py-24 md:px-10 md:py-20 xl:px-20
      '>
        <div className='
          w-full min-w-60 max-w-112
          flex flex-col
          gap-y-12
          justify-center items-center
        '>
          <span className='text-[32px] font-medium'>Create account</span>
          <div className='w-full flex flex-col gap-y-4'>
            <form
              className='flex flex-col gap-y-4'
              onSubmit={handleSignUp}
            >
              <EmailInput
                email={email}
                setEmail={setEmail}
                errorText={emailError}
                isLoading={isLoading}
              />
              <PasswordInput
                password={password}
                setPassword={setPassword}
                errorText={passwordError}
                infoText='Password must be at least 8 characters (a-z, A-Z, 0-9)'
                isLoading={isLoading}
              />
              <Button
                type='submit'
                title='Create account'
                errorText={error}
                isLoading={isLoading}
              />
            </form>
            <div className='flex items-center gap-x-4'>
              <hr className='w-full border-t-[1px] border-[#EDEDED]' />
              <span className='text-sm text-neutral-500'>OR</span>
              <hr className='w-full border-t-[1px] border-[#EDEDED]' />
            </div>
            <OAuth />
          </div>
          <div className='text-sm'>
            <span className='text-neutral-500'>I already have an account. </span>
            <Link to='/login'>Login</Link>
          </div>
        </div>
      </div>
      <About />
    </div>
  );
};

export default Login;
