import { useAuth } from 'context';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';

import { View } from 'types';

const Header = ({
  setIsMenuOpen,
  view,
}: {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  view: View;
}) => {
  const { auth } = useAuth();

  const navigate = useNavigate();

  const title = useMemo(() => {
    switch (view) {
      case 'commcoach':
        return 'Communication coach';
      case 'settings':
        return 'Settings';
      default:
        return 'Mental health check up';
    }
  }, [view]);

  return (
    <div className='
      h-16 md:h-[68px]
      flex md:justify-between items-center
      font-medium
    '>
      <button
        type='button'
        className='
          md:hidden
          outline-offset-2
          focus-visible:outline-2 focus-visible:outline-blue-300
          active:scale-110
          transition ease-out duration-300
        '
        onClick={() => {
          setIsMenuOpen(true);
        }}
      >
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M14.6665 20H25.3332M14.6665 16H25.3332M14.6665 24H25.3332'
            stroke='#525252'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
      <span className='text-[22px] md:text-2xl'>
        {title}
      </span>
      <div className='hidden lg:flex gap-3 items-center'>
        <span className='text-sm'>
          {auth?.displayName}
        </span>
        {auth?.photoURL && (
          <button
            type='button'
            tabIndex={-1}
            onClick={() => {
              navigate('/settings');
            }}
          >
            <img
              className='w-8 h-8 rounded-full'
              src={auth?.photoURL}
              alt='User profile'
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
