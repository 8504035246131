import iconInfo from 'assets/images/icon-info.png';

const InfoText = ({ text }: { text: string }) => {
  if (!text) {
    return <></>;
  }

  return (
    <div className='
      flex flex-row
      gap-x-1
      text-xs text-neutral-500
    '>
      <img
        src={iconInfo}
        className='w-[14px] h-4 -mt-[1px]'
        alt='Error icon'
      />
      <span>{text}</span>
    </div>
  );
};

export default InfoText;
