import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Auth, ProviderCredential } from 'types';

interface AuthContextTypes {
  auth: Auth | null | undefined;
  pendingCredential: ProviderCredential | null;
  reloadAuth: () => Promise<void>;
  resetAuth: () => void;
  setPendingCredential: React.Dispatch<React.SetStateAction<ProviderCredential | null>>;
}

const AuthContext = createContext<AuthContextTypes>({
  auth: undefined,
  pendingCredential: null,
  reloadAuth: async () => {},
  resetAuth: () => {},
  setPendingCredential: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [auth, setAuth] = useState<Auth | null | undefined>(undefined);
  const [pendingCredential, setPendingCredential] = useState<ProviderCredential | null>(null);

  const resetAuth = useCallback(() => {
    setAuth(undefined);
  }, []);

  const setAuthFromUser = useCallback((user: User | null) => {
    if (!user) {
      setAuth(null);
      return;
    }

    setAuth({
      email: user.email || '',
      displayName: user.displayName || '',
      photoURL: user.photoURL || '',
      uid: user.uid || '',
      emailVerified: user.emailVerified || false,
    });
  }, []);

  const reloadAuth = useCallback(async () => {
    const { currentUser } = getAuth();

    if (!currentUser) {
      setAuth(null);
      return;
    }

    await currentUser.reload();
    await currentUser.getIdToken(true);

    setAuthFromUser(currentUser);
  }, [setAuthFromUser]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      await user?.getIdToken(true);
      setAuthFromUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, [setAuthFromUser]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        pendingCredential,
        reloadAuth,
        resetAuth,
        setPendingCredential,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
