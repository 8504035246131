import DateController from './DateController';

const Header = () => {
  return (
    <div
      className='
        min-h-[56px]
        p-3 flex justify-center sm:justify-start
        bg-white border border-zinc-100 rounded-[10px] shadow-main
        text-sm font-medium
      '
    >
      <DateController />
    </div>
  );
};

export default Header;
