import { virtuosisInsance } from 'helpers/axios';
import { useAuth } from 'context';
import { getAuth, signOut } from 'firebase/auth';
import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';

import { Trial } from 'protobufs/requests/v1/trial';

import Header from 'components/Auth/Header'
import Button from 'components/Button';

const RequestTrial = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const { auth } = useAuth();

  const handleTrialRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      setError('');
      setSuccess('');
      const trial = Trial.encode({
        ID: auth?.uid || 'UNKNOWN',
        Name: auth?.displayName || 'UNKNOWN',
        Email: auth?.email || 'UNKNOWN',
        OrganisationId: 'UNKNOWN',
        OrganisationName: 'UNKNOWN',
        NumberOfMonths: 1,
        NumberOfLicences: 1,
      }).finish();
      const endpoint = '/requests/v1.0/trial';
      const data = new TextDecoder().decode(trial);
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      await virtuosisInsance.post(endpoint, data, { headers });
      setSuccess('Trial request sent successfully.');
    } catch (err) {
      setError('Something went wrong. Please try again.');
      Sentry.captureException(err);
    }
    setIsLoading(false);
  }, [auth]);

  return (
    <div className='
      min-h-screen
      grid justify-items-center
      px-5 py-24 md:px-10
    '>
      <Header />
      <div className='
        w-full min-w-60 max-w-112
        flex flex-col
        gap-y-12
        justify-center items-center
      '>
        <div className='flex flex-col justify-center items-center gap-y-4'>
          <span className='text-[32px] font-medium'>
            Request trial
          </span>
          <p className='text-neutral-600 text-center'>
            You don't have an active subscription. Request a trial to get started and we will get back to you shortly.
          </p>
        </div>
        <div className='w-full flex flex-col gap-y-4'>
          <Button
            title='Request trial'
            onClick={handleTrialRequest}
            errorText={error}
            isLoading={isLoading}
            successText={success}
          />
          <div className='flex items-center gap-x-4'>
            <hr className='w-full border-t-[1px] border-[#EDEDED]' />
            <span className='text-sm text-neutral-500'>OR</span>
            <hr className='w-full border-t-[1px] border-[#EDEDED]' />
          </div>
          <Button
            title='Log out'
            pallet='secondary'
            onClick={async () => {
              await signOut(getAuth());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestTrial;
