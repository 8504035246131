import Header from './Header';

const Charts = () => {
  return (
    <div className='grid grid-rows-[auto_1fr]'>
      <Header />
      <div></div>
    </div>
  )
};

export default Charts;
