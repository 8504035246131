import { useAuth } from 'context';
import { getAuth, sendEmailVerification, signOut } from 'firebase/auth';
import { isTooManyRequests } from 'helpers/firebase';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';

import Button from 'components/Button';
import Init from 'components/Init';
import { ErrorText, SuccessText } from 'components/Text';

import Header from './Header';

const EmailValidation = () => {
  const hasClickedContinueRef = useRef(false);

  const [continueError, setContinueError] = useState('');
  const [continueWarn, setContinueWarn] = useState('');
  const [logoutError, setLogoutError] = useState('');
  const [resendError, setResendError] = useState('');
  const [resendSuccess, setResendSuccess] = useState('');

  const { auth, reloadAuth } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      setLogoutError('');
      const firebaseAuth = getAuth();
      await signOut(firebaseAuth);
    } catch (err) {
      setLogoutError('An error occurred - please try again');
      Sentry.captureException(err);
    }
  };

  const handleContinue = async () => {
    try {
      hasClickedContinueRef.current = true;
      setContinueError('');
      setContinueWarn('');
      await reloadAuth();
    } catch (err) {
      setContinueError('An error occurred - please try again');
      Sentry.captureException(err);
    }
  };

  const handleResend = async () => {
    try {
      setResendError('');
      setResendSuccess('');

      const { currentUser } = getAuth();

      if (!currentUser) {
        throw new Error('currentUser is unexpectedly null');
      }

      await sendEmailVerification(currentUser)

      setResendSuccess('Email sent successfully');
    } catch (err) {
      if (isTooManyRequests(err)) {
        setResendError('Too many requests - please try again in a few minutes');
        return;
      }

      setResendError('An error occurred - please try again');
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if (auth?.emailVerified) {
      const from = location.state?.from?.pathname || '/';  
      navigate(from, { replace: true });
    } else if (hasClickedContinueRef.current) {
      setContinueWarn('Please verify your email to continue');
    }
  }, [auth, location, navigate]);

  useEffect(() => {
    document.title = 'Virtuosis - Verify your email';
  }, []);

  if (!auth) {
    return <Init />;
  }

  return (
    <div className='
      min-h-screen
      grid justify-items-center
      px-5 py-24 md:px-10
    '>
      <Header />
      <div className='
        w-full min-w-60 max-w-112
        flex flex-col
        gap-y-12
        justify-center items-center
      '>
        <div className='flex flex-col gap-y-4 items-center text-center'>
          <span className='text-[32px] font-medium'>Verify your email</span>
          <p className='text-neutral-600'>
            Email: <span className='text-[#EA580C]'>{auth.email}</span>
          </p>
          <p className='text-neutral-600'>
            An email has been sent to your email address. Please click the link in the email to continue.
          </p>
        </div>
        <div className='w-full flex flex-col gap-y-4'>
          <Button
            title='Continue'
            errorText={continueError}
            warnText={continueWarn}
            onClick={handleContinue}
          />
          <div className='flex items-center gap-x-4'>
            <hr className='w-full border-t-[1px] border-[#EDEDED]' />
            <span className='text-sm text-neutral-500'>OR</span>
            <hr className='w-full border-t-[1px] border-[#EDEDED]' />
          </div>
          <Button
            title='Log out'
            pallet='secondary'
            errorText={logoutError}
            onClick={handleLogout}
          />
        </div>
        <div className='flex flex-col gap-y-1 items-center'>
          <div className='text-sm'>
            <span className='text-neutral-500'>I don't see an email? </span>
            <button
              type='button'
              className='     
                text-v-purple font-medium
                outline-offset-2
                focus-visible:outline-2 focus-visible:outline-blue-300
              '
              onClick={handleResend}
            >
              Resend
            </button>
          </div>
          <ErrorText text={resendError} />
          <SuccessText text={resendSuccess} />
        </div>
      </div>
    </div>
  );
};

export default EmailValidation;
