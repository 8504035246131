import { Loading } from 'components/Animations';

const Init = ({ isLoading = false}: { isLoading?: boolean }) => {
  return (
    <div className='min-h-screen grid place-items-center'>
      {isLoading && <Loading />}
    </div>
  );
};

export default Init;
