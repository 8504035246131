import { initializeApp } from 'firebase/app';

const devFirebaseConfig = {
    apiKey: 'AIzaSyBl2J0SZHMQNTbHVZjQdUNtDGYJVTl1MEM',
    authDomain: 'virtuosis-dev.firebaseapp.com',
    projectId: 'virtuosis-dev',
    storageBucket: 'virtuosis-dev.firebasestorage.app',
    messagingSenderId: '163795747423',
    appId: '1:163795747423:web:c1e5bb4e08399b71bbd1ea',
    measurementId: 'G-BGVFW7G1M8'
} as const;

const prodFirebaseConfig = {
    apiKey: 'AIzaSyB7kuHsUNiLUoxzH-8g8DJtmlhAKHkvYDg',
    authDomain: 'auth.virtuosis.ai',
    projectId: 'virtuosis-prod',
    storageBucket: 'virtuosis-prod.firebasestorage.app',
    messagingSenderId: '1086488561042',
    appId: '1:1086488561042:web:5ce74f684de8011e236d4b',
    measurementId: 'G-7C8Z3E42RT'
} as const;

const getFirebaseConfig = () => {
  return process.env.NODE_ENV === 'production' ? prodFirebaseConfig : devFirebaseConfig;
};

// JavaScript SDK: https://firebase.google.com/docs/web/setup
export const initializeFirebaseApp = () => {
  return initializeApp(getFirebaseConfig());
}

export const isInvalidCredentialsError = (error: any) => {
  return error?.code === 'auth/invalid-credential';
};

export const isAccountExistsWithDifferentCredentialError = (error: any) => {
  return error?.code === 'auth/account-exists-with-different-credential';
};

export const isEmailAlreadyInUseError = (error: any) => {
  return error?.code === 'auth/email-already-in-use';
};

export const isPopupClosedByUser = (error: any) => {
  return error?.code === 'auth/popup-closed-by-user';
};

export const isCancelledPopupRequest = (error: any) => {
  return error?.code === 'auth/cancelled-popup-request';
};

export const isTooManyRequests = (error: any) => {
  return error?.code === 'auth/too-many-requests';
};
