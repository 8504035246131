import { useState } from 'react';

import Input from './Input';

import iconEyeOff from 'assets/images/icon-eye-off.png';
import iconEyeOn from 'assets/images/icon-eye-on.png';
import iconLock from 'assets/images/icon-lock.png';

const PasswordInput = ({
  errorText,
  infoText,
  isLoading,
  password,
  setPassword,
  successText,
}: {
  errorText?: string,
  infoText?: string,
  isLoading?: boolean,
  password: string,
  setPassword: React.Dispatch<React.SetStateAction<string>>,
  successText?: string,
}) => {
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className='relative'>
      <Input
        id='password-input'
        type={isPasswordVisible ? 'text' : 'password'}
        errorText={errorText}
        infoText={infoText}
        successText={successText}
        isLoading={isLoading}
        onChange={(e) => { setPassword(e.target.value || ''); }}
        onFocus={() => { setIsPasswordFocused(true); }}
        onBlur={() => { setIsPasswordFocused(false); }}
      />
      {!isPasswordFocused && !password && (
        <label 
          htmlFor='password-input'
          className='
            absolute flex items-center
            gap-x-2 top-2 left-2.5
            text-neutral-500
            select-none
            hover:cursor-text
          '
        >
          <img
            src={iconLock}
            className='w-[18px] h-[18px]'
            alt='Lock icon'
          />
          <span>Password</span>
        </label>
      )}
      <button
        type='button'
        className='
          absolute top-2.5 right-2.5
          outline-offset-2
          focus-visible:outline-2 focus-visible:outline-blue-300
        '
        onClick={() => { setIsPasswordVisible(!isPasswordVisible); }}
      >
        <img
          src={isPasswordVisible ? iconEyeOff : iconEyeOn}
          className='w-[20px] h-[20px] '
          alt='Eye off icon'
        />
      </button>
    </div>
  );
};

export default PasswordInput;
