import { useState } from 'react';

import Input from './Input';

import iconMail from 'assets/images/icon-mail.png';

const EmailInput = ({
  email,
  setEmail,
  errorText,
  infoText,
  isLoading,
  successText,
}:{
  email: string,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
  errorText?: string,
  infoText?: string,
  isLoading?: boolean,
  successText?: string,
}) => {
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  return (
    <div className='relative'>
      <Input 
        id='email-input'
        errorText={errorText}
        infoText={infoText}
        successText={successText}
        isLoading={isLoading}
        value={email}
        onChange={(e) => { setEmail(e.target.value || ''); }}
        onFocus={() => { setIsEmailFocused(true); }}
        onBlur={() => { setIsEmailFocused(false); }}
      />
      {!isEmailFocused && !email && (
        <label
          htmlFor='email-input'
          className='
            absolute flex items-center
            gap-x-2 top-2 left-2.5
            text-neutral-500
            select-none
            hover:cursor-text
          '
        >
          <img
            src={iconMail}
            className='w-[18px] h-[18px]'
            alt='Email icon'
          />
          <span>Email</span>
        </label>
      )}
    </div>
  );
};

export default EmailInput;
