import axios from 'axios';
import { virtuosisInsance } from 'helpers/axios';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';

import Init from 'components/Init';
import { SubscriptionError } from 'components/Subscription';

const SubscriptionProtected = ({ children }: { children: JSX.Element }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasLicence, setHasLicence] = useState<boolean | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();

    (async (signal: AbortSignal) => {
      try {
        setHasError(false);
        const endpoint = '/subscription/v1.0/status/google';
        const response = await virtuosisInsance.get(endpoint, { signal });
        const data = response?.data;
        const status = data?.hasLicence;
        if (typeof status !== 'boolean') {
          throw new Error('Invalid response data: ' + JSON.stringify(data));
        } 
        setHasLicence(status);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }
        setHasError(true);
        Sentry.captureException(err);
      }
    })(abortController.signal);

    return () => { abortController.abort(); };
  }, [navigate]);

  if (hasError) {
    return <SubscriptionError />;
  }

  if (hasLicence === null) {
    return <Init isLoading={true} />;
  }

  return hasLicence ? children : <Navigate to='/trial' />;
};

export default SubscriptionProtected;
