export enum STORAGE_KEYS {
    EMAIL = 'email',
};

export const getStoredValue = (key: STORAGE_KEYS): string | null => {
    return localStorage.getItem(key);
};

export const setStoredValue = (key: STORAGE_KEYS, value: string) => {
    if (value) {
        localStorage.setItem(key, value);
    } else {
        localStorage.removeItem(key);
    }
};

export const clearStoredValue = (key: string) => {
    localStorage.removeItem(key);
};
