import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';

import Header from 'components/Auth/Header'
import Button from 'components/Button';

const SubscriptionError = () => {
  const navigate = useNavigate();

  return (
    <div className='
      min-h-screen
      grid justify-items-center
      px-5 py-24 md:px-10
    '>
      <Header />
      <div className='
        w-full min-w-60 max-w-112
        flex flex-col
        gap-y-12
        justify-center items-center
      '>
        <div className='flex flex-col justify-center items-center gap-y-4'>
          <span className='text-[32px] font-medium '>
            Oops...
          </span>
          <p className='text-neutral-600 text-center'>
            An unexpected error occurred while checking your subscription status. Would you mind trying again?
          </p>
          <p className='text-neutral-600 text-center'>
            The error has been logged, but if the issue persists, please contact our support team.
          </p>
        </div>
        <div className='w-full flex flex-col gap-y-4'>
          <Button
            title='Try again'
            onClick={() => {
              navigate(0);
            }}
          />
          <div className='flex items-center gap-x-4'>
            <hr className='w-full border-t-[1px] border-[#EDEDED]' />
            <span className='text-sm text-neutral-500'>OR</span>
            <hr className='w-full border-t-[1px] border-[#EDEDED]' />
          </div>
          <Button
            title='Log out'
            pallet='secondary'
            onClick={async () => {
              await signOut(getAuth());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionError;
