import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAuth } from 'context';

import Init from 'components/Init';

const AuthProtected = ({ children }: { children: JSX.Element }) => {
  const { auth, resetAuth } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth === null) {
      resetAuth();
      navigate('/login');
    }
  }, [auth, navigate, resetAuth]);


  if (auth === undefined) {
    return <Init isLoading={false} />;
  }

  return auth ? children : <Init isLoading={false} />;
};

export default AuthProtected;
