import { useState } from 'react';
import isEmail from 'validator/lib/isEmail';

const useFormValidation = () => {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const isPasswordValid = (password: string): boolean => {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters (a-z, A-Z, 0-9)');
      return false;
    }

    if (!upperCaseRegex.test(password)) {
      setPasswordError('Password must contain at least one uppercase letter');
      return false;
    }

    if (!lowerCaseRegex.test(password)) {
      setPasswordError('Password must contain at least one lowercase letter');
      return false;
    }

    if (!numberRegex.test(password)) {
      setPasswordError('Password must contain at least one number');
      return false;
    }

    return true;
  };

  const isEmailValid = (email: string): boolean => {
    if (!isEmail(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    }

    return true;
  };

  const resetFormErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  return {
    emailError,
    passwordError,

    setEmailError,
    setPasswordError,

    isEmailValid,
    isPasswordValid,
    resetFormErrors,
  };
};

export default useFormValidation;
