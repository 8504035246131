import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { useFormValidation } from 'hooks';

import Button from 'components/Button';
import { EmailInput } from 'components/Input'
import Link from 'components/Link';

import Header from './Header';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const { emailError, isEmailValid, resetFormErrors } = useFormValidation();

  const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      setError('');
      setSuccess('');
      resetFormErrors();

      if (!isEmailValid(email)) {
        return;
      }

      setIsLoading(true);

      const firebaseAuth = getAuth();
      await sendPasswordResetEmail(firebaseAuth, email)

      setSuccess('A password reset link has been sent to your email');
    } catch (err) {
      setError('An error occurred - please try again');
      Sentry.captureException(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    document.title = 'Virtuosis - Reset your password';
  }, []);

  return (
    <div className='
      min-h-screen
      grid justify-items-center
      px-5 py-24 md:px-10
    '>
      <Header />
      <div className='
        w-full min-w-60 max-w-112
        flex flex-col
        gap-y-12
        justify-center items-center
      '>
        <div className='flex flex-col gap-y-4 items-center text-center'>
          <span className='text-[32px] font-medium'>Reset your password</span>
          <p className='text-neutral-600'>
            Enter your email address and we will send you a link to reset your password.
          </p>
        </div>
        <form
          className='w-full flex flex-col gap-y-4'
          onSubmit={handlePasswordReset}
        >
          <EmailInput
            email={email}
            setEmail={setEmail}
            errorText={emailError}
            isLoading={isLoading}
          />
          <Button
            type='submit'
            title='Send reset link'
            errorText={error}
            successText={success}
            isLoading={isLoading}
          />
        </form>
        <div className='text-sm'>
          <span className='text-neutral-500'>I have reset my password. </span>
          <Link to='/login'>Login</Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
