import { useAuth } from 'context';
import { getAuth, linkWithCredential, signInWithEmailAndPassword } from 'firebase/auth';
import { isInvalidCredentialsError, isTooManyRequests } from 'helpers/firebase';
import {
  clearStoredValue,
  getStoredValue,
  setStoredValue,
  STORAGE_KEYS,
} from 'helpers/storage';
import { useFormValidation } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import isEmail from 'validator/lib/isEmail';

import Button from 'components/Button';
import { EmailInput, PasswordInput } from 'components/Input';
import Link from 'components/Link';

import About from './About';
import Header from './Header';
import OAuth from './OAuth';

const Login = () => {
  const storedEmail = getStoredValue(STORAGE_KEYS.EMAIL) || '';

  const [email, setEmail] = useState(storedEmail);
  const [error, setError] = useState('');
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(!!storedEmail);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { pendingCredential } = useAuth();

  const navigate = useNavigate();

  const {
    emailError,
    passwordError,
    isEmailValid,
    isPasswordValid,
    resetFormErrors,
  } = useFormValidation();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      setError('');
      resetFormErrors();

      const passwordTrimmed = password.trim();

      const isEmailInvalid = !isEmailValid(email);
      const isPasswordInvalid = !isPasswordValid(passwordTrimmed);

      if (isEmailInvalid || isPasswordInvalid) {
        return;
      }

      setIsLoading(true);

      const firebaseAuth = getAuth();
      const { user } = await signInWithEmailAndPassword(firebaseAuth, email, passwordTrimmed);

      if (
        pendingCredential
        && pendingCredential.email === user.email
        && (
          pendingCredential.credential.providerId === 'microsoft.com'
          || pendingCredential.credential.providerId === 'google.com'
        )
      ) {
        await linkWithCredential(user, pendingCredential.credential);
      }

      setIsLoading(false);

      navigate('/');
    } catch (err) {
      if (isInvalidCredentialsError(err)) {
        setError('Invalid email or password');
      } else if (isTooManyRequests(err)) {
        setError('Too many requests - please try again later');
      } else {
        setError('An error occurred - please try again');
        Sentry.captureException(err);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEmail(email) && isRememberMeChecked) {
      setStoredValue(STORAGE_KEYS.EMAIL, email);
      return;
    } 

    clearStoredValue(STORAGE_KEYS.EMAIL);
  }, [email, isRememberMeChecked]);

  useEffect(() => {
    document.title = 'Virtuosis - Login';
  }, []);

  return (
    <div className='min-h-screen grid md:grid-cols-2'>
      <div className='absolute w-full block md:hidden'>
        <Header />
      </div>
      <div className='
        flex justify-center items-center
        px-5 py-24 md:px-10 md:py-20 xl:px-20
      '>
        <div className='
          w-full min-w-60 max-w-112
          flex flex-col
          gap-y-12
          justify-center items-center
        '>
          <span className='text-[32px] font-medium'>Login</span>
          <div className='w-full flex flex-col gap-y-4'>
            <form
              className='flex flex-col gap-y-4'
              onSubmit={handleLogin}
            >
              <EmailInput
                email={email}
                setEmail={setEmail}
                errorText={emailError}
                isLoading={isLoading}
              />
              <PasswordInput
                password={password}
                setPassword={setPassword}
                errorText={passwordError}
                isLoading={isLoading}
              />
              <Button
                type='submit'
                title='Login'
                errorText={error}
                isLoading={isLoading}
              />
            </form>
            <div className='w-full flex justify-between text-sm'>
              <div className='flex items-center'>
                <input
                  id='remember-me-checkbox'
                  type='checkbox'
                  className='
                    accent-[#FAC34F]
                    hover:cursor-pointer hover:accent-[#F9AD29]
                    outline-offset-2
                    focus-visible:outline-2 focus-visible:outline-blue-300
                  '
                  onChange={(e) => { setIsRememberMeChecked(e.target.checked || false); }}
                  checked={isRememberMeChecked}
                />
                <button
                  type='button'
                  className='pl-2'
                  tabIndex={-1}
                  onClick={() => { setIsRememberMeChecked(!isRememberMeChecked); }}
                >
                  Remember me
                </button>
              </div>
              <Link to='/password'>Forgot password</Link>
            </div>
            <div className='flex items-center gap-x-4'>
              <hr className='w-full border-t-[1px] border-[#EDEDED]' />
              <span className='text-sm text-neutral-500'>OR</span>
              <hr className='w-full border-t-[1px] border-[#EDEDED]' />
            </div>
            <OAuth />
          </div>
          <div className='text-sm'>
            <span className='text-neutral-500'>I don't have an account yet. </span>
            <Link to='/signup'>Sign up</Link>
          </div>
        </div>
      </div>
      <About />
    </div>
  );
};

export default Login;
