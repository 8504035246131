import Logo from 'components/Logo';

const Header = () => {
  return (
    <div className='
      absolute top-8
      w-full min-w-60
      flex justify-center md:justify-start
      md:px-10
    '>
      <div className='w-[132px] md:w-auto'>
        <Logo />
      </div>
    </div>
  );
};

export default Header;
